'use strict';

let PDI = PDI || {};
let UTILS = UTILS || {};
let googleMapLoaded = false;
let staticFolder = '/images';


PDI.utils = UTILS || {};
PDI.metaTags = {};


UTILS.sum = function sum(a, b) {
    return a + b;
}

PDI.utils.mobileMenuOpen = function() {
    let menu = $('.l-navbar__menu');

    $('.js-hamburger').on( 'click', function() {
        $(this).toggleClass('hamburger--open');
        menu.toggleClass('l-navbar__menu--mob-open');
    });
} 


PDI.utils.checkingFormOnFillingOut = function() {
    $('.js-input').on('focus', function(){
        $(this).parent('.form__group').addClass('form__group--active '); 
        $(this).next('.form__label').addClass('form__label--transparent');
        $(this).parent('.form__group').find('.form__label-wrap').addClass('form__label-wrap--show');
    });
    
    $('.js-input').on('focusout', function (){
        $(this).parent('.form__group').removeClass('form__group--active ');
        $(this).next(".form__label").removeClass('form__label--transparent');
        $(this).parent('.form__group').find('.form__label-wrap').removeClass('form__label-wrap--show');

        if ($(this).val() !== '') {
            $(this).next('.form__label').addClass('form__label--transparent');
        } else {
            $(this).next('.form__label').removeClass('form__label--transparent');
        }
    });
}

PDI.utils.checkingFormOnFillingOut();

PDI.utils.selectStyles = function() {
    $('select').each(function(){
        var $this = $(this), numberOfOptions = $(this).children('option').length;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');
    
        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);
      
        for (var i = 1; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val(),
                'class': 'js-filter-ajax',
                'data-filter-type': 'category',
                'name': 'category',
                'data-category': 'blog',
                on: {
                    click: function() {
                        var thisEl = $(this);
                        PDI.refinement.selectEvent(thisEl);
                    }
                },
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
        $styledSelect.text($this.find(":selected").text());
      
        $styledSelect.click(function(e) {
            e.stopPropagation();

            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
            
            if ($(this).closest('.refinement__item').hasClass('refinement__item--arrow-down--rotate')) {
                $(this).closest('.refinement__item').removeClass('refinement__item--arrow-down--rotate');
            } else {
                $(this).closest('.refinement__item').addClass('refinement__item--arrow-down--rotate');
            }
            
        });
      
        $listItems.click(function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $(this).closest('.refinement__item').removeClass('refinement__item--arrow-down--rotate');
        });
      
        $(document).click(function() {
            $styledSelect.removeClass('active');
            $list.hide();
            $styledSelect.closest('.refinement__item').removeClass('refinement__item--arrow-down--rotate');
        });
    
    });
}


PDI.refinement = {
    groupRevealed: null,
    getCurrentCategorySlug: function() {
        return $('[data-category]').attr('data-category');
    },
    getCategoryName: function(slug) {
        var name = $('[data-param="' + slug + '"]').html();

        if (name) {
            name = name.trim();
        }

        return name;
    },
    getLabelName: function(slug) {
        var name = $('input[value="' + slug + '"]').parent().find('.js-label-name').html();

        if (name) {
            name = name.trim();
        }

        return name;
    },
    getCurrentLabelSlug: function() {
        return $('input[name="labelSlug"]:checked').val();
    },
    filterBy: function(type, value, withoutTopicRefinement) {
        var categorySlug = PDI.refinement.getCurrentCategorySlug();
        var labelSlug = PDI.refinement.getCurrentLabelSlug();
        var url;

        if (type === 'category') {
            if (withoutTopicRefinement) {
                url = value;
                PDI.refinement.trackEvent(value);
            } else {
                url = value + (labelSlug ? '/' + labelSlug : '');
                PDI.refinement.trackEvent(value, labelSlug);
            }

        } else if (type === 'label') {
            url = categorySlug + '/' + value;
            PDI.refinement.trackEvent(categorySlug, value);

        } else {
            url = categorySlug;
            PDI.refinement.trackEvent(categorySlug);
        }

        history.pushState({url: url}, null, url);
        PDI.pushStateCounter++;
        PDI.refinement.requestContent(url);
    },

    trackEvent: function(categorySlug, labelSlug) {
        var ga = window.ga || null;
        if (!ga) {
            return true;
        }

        var categoryName = PDI.refinement.getCategoryName(categorySlug);
        var labelName = PDI.refinement.getLabelName(labelSlug);
        var data = {
            'hitType': 'event',
            'eventCategory': 'Resources: ' + categoryName,
            'eventAction': labelSlug ? 'refined with' : 'selected',
            'eventLabel': labelSlug ? labelSlug : ''
        };

        ga('send', data);
    },

    findParams: function(value) {
        if (value.indexOf('?') >= 0) {
            value += '/';
            return value;
        } else {
            value += '?';///?
            return value;
        }
    },
    requestContent: function(url) {
        $('.loading').fadeIn();

        if (PDI.infiniteElement) {
            PDI.infiniteElement.destroy();
        }

        if ( url.slice(-1) === '/' ) {
            url = url.slice(0, -1);
        }

        url += (PDI.refinement.groupRevealed ? '?groupRevealed=' + PDI.refinement.groupRevealed : '');
        var newUrl = PDI.refinement.findParams(url);

        if ( newUrl.slice(-1) === '/' ) {
            newUrl = newUrl.slice(0, -1);
        }
        console.log(newUrl);

        setTimeout(function() {
            $.ajax({
                url: newUrl + Math.random(),
                dataType: 'html',
                success: function(responseText) {

                    $('#js-content').replaceWith(responseText);

                    var codes = $('#js-content').find("script");
                    for (var i=0; i<codes.length; i++) {
                        eval(codes[i].text);
                    }

                    document.title = PDI.metaTags.title;
                    $('meta[name=description]').attr('content', PDI.metaTags.description);

                    // $('html, body').animate({ scrollTop: 0 }, 'slow');

                    Waypoint.destroyAll();
                    PDI.utils.animationedSections();
                    $('.js-equal-height').matchHeight();
                    PDI.utils.ajaxPopup();
                    PDI.refinement.init();
                    PDI.utils.selectStyles();

                    setTimeout(function() {
                        PDI.utils.infiniteScroll(PDI.utils.waypointCallback);
                        Waypoint.refreshAll();
                    }, 1000);

                    setTimeout(function() {
                        $('.loading').fadeOut();
                    }, 200);
                },

                error: function(responseText) {
                    setTimeout(function() {
                        $('.loading').fadeOut();
                    }, 200);
                }
            });
        }, 300);

        return false;
    },
    initPopstate: function() {
        window.addEventListener('popstate', function(e) {
            var character = e.state;

            if (character === null && PDI.pushStateCounter) {
                location.reload();
            } else {
                PDI.refinement.requestContent(character.url);
            }
        });
    },

    selectEvent: function(el) {
        var filterPoint = el;
        var val = filterPoint.attr('rel');

        if (val !== '-1') {

            var filterType = filterPoint.attr('data-filter-type'),
                selectedOption = filterPoint.parent().parent().find('select').find(":selected"),
                slug = val,
                withoutTopicRefinement = selectedOption.data('no-topics') || false;

                // console.log(selectedOption, withoutTopicRefinement);

            PDI.refinement.filterBy(filterType, slug, withoutTopicRefinement);
        }


        // $('.js-filter-ajax').on('click', function(e) {
        //     var self = this;
        //     var val = $(this).val();

        //     if (val !== '-1') {

        //         var filterType = self.getAttribute('data-filter-type'),
        //             selectedOption = $(self).find(":selected"),
        //             slug = val,
        //             withoutTopicRefinement = selectedOption.data('no-topics') || false;

        //         PDI.refinement.filterBy(filterType, slug, withoutTopicRefinement);
        //     }
        // });

    },

    init: function() {
        // this.selectEvent();
    }
};

PDI.lazyLoadingOfImages = function() {
    $('.lazy').Lazy({
        scrollDirection: 'vertical',
        effect: 'fadeIn',
        visibleOnly: true,
        afterLoad: function(element) {
            $(element).removeClass('lazy');
        }
    });
};

//Map for the contact page
PDI.contact = {
    loadScript: function() {
        if (!googleMapLoaded) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyApscPDwQTkvp3McttPpi_hhqZlzt3emAc&' +
                'callback=PDI.contact.map';
            document.body.appendChild(script);
            googleMapLoaded = true;
        } else {
            PDI.contact.map();
        }
    },

    map: function() {
        function init() {
            var draggable;
            var isMobile = function() {
            try{ document.createEvent("TouchEvent"); return true; }
                catch(e){ return false; }
            };
            if(isMobile()) {
                draggable = false;
            } else {
                draggable = true;
            }

            var maps = document.querySelectorAll('.js-map');

            [].forEach.call(maps, function(mapObj) {

                var myLatLngX = mapObj.getAttribute('data-latitude');
                var myLatLngY = mapObj.getAttribute('data-longitude');

                var mapOptions = {
                    zoom: 15,
                    center: new google.maps.LatLng(myLatLngX,myLatLngY),
                    disableDefaultUI: true,
                    scrollwheel: false,
                    draggable: draggable,

                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.LARGE,
                        position: google.maps.ControlPosition.RIGHT_TOP
                    },

                    styles: [
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        }
                    ]
                };

                var mapElement = mapObj;
                var map = new google.maps.Map(mapElement, mapOptions);
                var image = staticFolder +'/location-icon.png';
                var marker = new google.maps.Marker({
                    title: 'PDI Office',
                    position: map.getCenter(),
                    map: map,
                    icon: image,
                    // icon: {
                    //     url: "images/icons/location-2.svg",
                    //     scaledSize: new google.maps.Size(50, 71)
                    // }
                });

                google.maps.event.addDomListener(window, 'resize', function() {
                    var center = map.getCenter();
                    google.maps.event.trigger(map, 'resize');
                    map.setCenter(center);
                });
            });
        }
        init();
    }
};

UTILS.scrollToID = function scrollToID( id = '#main', context = 'html,body') {
    var path = window.location.pathname;
    var offSet = 80;
    // var x = UTILS.screenSize().x;

    // if (x < 768) {
    //     offSet = 60;
    // }
    // var targetOffset = $(id).offset().top - offSet;
    var targetOffset = $(id).offset().top;

    $(context).animate({scrollTop:targetOffset}, 1000);
};

$('.js-go-down').on('click', function() {
    UTILS.scrollToID();
});


PDI.utils.makeEqualHeadline = function() {
    $('.js-equal-headline-block').each(function(i, elem) {
        $(elem)
            .find('.js-equal-headline')   
            .matchHeight({byRow: false}); 
    });
};

PDI.calculate100vh = function() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

function runWebsiteScripts() {
    PDI.refinement.init();
    PDI.utils.makeEqualHeadline();
    PDI.utils.openPopupOnClick();
    UTILS.magnific();
    PDI.utils.selectStyles();
    PDI.utils.infiniteScroll(PDI.utils.makeEqualHeadline);
    PDI.utils.checkingFormOnFillingOut();
    PDI.utils.animationedSections();
    PDI.utils.hashAnchorClick();
    PDI.calculate100vh();
    PDI.lazyLoadingOfImages();

    $('.js-go-down').on('click', function() {
        UTILS.scrollToID();
    });


    if ($('.js-map').length) {
        const mapWaypoint = new Waypoint({
            element: $('.js-map')[0],
            handler: function(direction) {
                if (direction === 'down') {
                    PDI.contact.loadScript();
                }
            },
            offset: '80%'
        });
    }
    
}

function trackPageGTM() {
    if (window['dataLayer'] !== undefined) {
        let pagePath = window.location.pathname;
        
        window.dataLayer.push({
            'event': 'content-view',
            'content-name': pagePath
        });
    }
}

/*
    * -----------------------------------------------------------
    * // Dynamic loading via Barba.js
    *
*/

var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
        Promise
            .all([this.fadeOut(), this.newContainerLoading])
            .then(this.fadeIn.bind(this));
    },
  
    fadeOut: function() {
        var deferred = new jQuery.Deferred();
        let menuWasOpened = false;
        let fadeOutTimeout = 200;
    
        if (document.querySelector('.js-hamburger')) {
            if (document.querySelector('.js-hamburger').classList.contains('hamburger--open')) {
                document.querySelector('.js-hamburger').click();
                menuWasOpened = true;
            }
        }

        setTimeout(function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            deferred.resolve();
        }, fadeOutTimeout);


        return deferred.promise().then(() => {
            return $(this.oldContainer).animate({ opacity: 0 }, 400).promise();
        });

        // return $(this.oldContainer).animate({ opacity: 0 }).promise();
    },
  
    fadeIn: function() {

        $(window).scrollTop(0);
    
        var _this = this;
        var $el = $(this.newContainer);
    
        $(this.oldContainer).hide();
    
        $el.css({
            visibility : 'visible',
            opacity : 0
        });
    
        $el.animate({ opacity: 1 }, 200, function() {
            _this.done();
        });
    }
});
  
Barba.Pjax.getTransition = function() { 
    return FadeTransition;
};

Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus) {
    runWebsiteScripts();
});

Barba.Dispatcher.on('newPageReady', function(current, prev, container) {
    history.scrollRestoration = 'manual';
    trackPageGTM();
});

$(document).ready(function() {
    let barbaContainer = $('.barba-container').length;

    if (barbaContainer) {
        Barba.Pjax.start();
        Barba.Pjax.getTransition();
        Barba.Prefetch.init();
    } else {
        runWebsiteScripts();
    }

    PDI.utils.mobileMenuOpen();
});